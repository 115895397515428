import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';
import { from as mergeLinks } from '@apollo/client/link/core/from';
import { createUploadLink } from 'apollo-upload-client';
import { getSelectedOrganizationIdEncr, setSelectedOrganizationIdEncr } from 'UI/lib';
import useAvailableLanguages from 'UI/customHooks/useAvailableLanguages';
import {
  headerContext,
  HEADER_USERID_IDENTITY,
  HEADER_USERTOKEN_IDENTITY,
} from 'UI/constants/apiHeaders';
import { RetryLink } from '@apollo/client/link/retry';
import { getApiHeaders } from './getApiHeaders';
import {
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_USER_TOKEN,
} from './ui/constants/localStorage';

if (typeof URLSearchParams !== 'function') {
  import('url-search-params-polyfill');
}

const createApolloClientInstance = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const availableLanguages = useAvailableLanguages();
  const defaultLang = availableLanguages.find(language => language.isDefault).languageCode;

  const middlewareLink = new ApolloLink((operation, forward) => {
    const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID);
    const headers = getApiHeaders({
      userId,
      token: localStorage.getItem(LOCAL_STORAGE_USER_TOKEN),
      language: localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || defaultLang,
      organizationId: getSelectedOrganizationIdEncr(userId),
    });

    operation.setContext({
      headers,
    });

    return forward(operation);
  });

  const authLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();

      const {
        response: { headers },
      } = context;

      if (headers) {
        const headersToken = headers.get(headerContext[HEADER_USERTOKEN_IDENTITY].key);
        const headersUserId = headers.get(headerContext[HEADER_USERID_IDENTITY].key);

        if (headersToken) {
          localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, headersToken);
        }

        if (headersUserId) {
          const currentUserId = context.headers[headerContext[HEADER_USERID_IDENTITY].key];

          if (currentUserId && currentUserId !== headersUserId) {
            const selectedOrganizationId = getSelectedOrganizationIdEncr(currentUserId);

            if (selectedOrganizationId) {
              setSelectedOrganizationIdEncr(selectedOrganizationId, headersUserId);
            }
          }

          localStorage.setItem(LOCAL_STORAGE_USER_ID, headersUserId);
        }
      }

      return response;
    });
  });

  const uploadLink = createUploadLink({
    uri: `${process.env.BACKEND_URL}/graphql`,
    fetch,
    credentials: 'include',
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 0,
    },
  });

  const inMemoryCache = new InMemoryCache({
    addTypename: true,
  });

  return new ApolloClient({
    link: mergeLinks([authLink, retryLink, middlewareLink, uploadLink]),
    cache: inMemoryCache.restore(window.__APOLLO_CLIENT__),
    shouldBatch: false,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
};

export default (() => {
  let instance = null;
  return () => {
    if (!instance) {
      instance = createApolloClientInstance();
    }
    return instance;
  };
})();
