const WEB_PROTOCOLS = ['//', 'http:', 'https:', 'ftp:', 'file:'];

export const getIsInternalUrl = url => !WEB_PROTOCOLS.find(protocol => url.startsWith(protocol));
export const getIsExternalUrl = url => !getIsInternalUrl(url);

export const getFullCdnUrl = (targetUrl, cdnUrl) => {
  if (!targetUrl) {
    return null;
  }

  const encodedURI = encodeURI(targetUrl);

  if (WEB_PROTOCOLS.find(protocol => targetUrl.startsWith(protocol))) {
    return encodedURI;
  }
  return `${cdnUrl}${encodedURI}`;
};
