const HEADER_TENANTID_KEY = 'x-tenant-id';
const HEADER_TENANTID_IDENTIFIER = 'tenantId';
const HEADER_TENANTID_IDENTITY = 'Tenant';

const HEADER_USERID_KEY = 'x-user-id';
const HEADER_USERID_IDENTIFIER = 'userId';
const HEADER_USERID_IDENTITY = 'User';

const HEADER_USERTOKEN_KEY = 'x-user-token';
const HEADER_USERTOKEN_IDENTIFIER = 'userToken';
const HEADER_USERTOKEN_IDENTITY = 'UserToken';

const HEADER_ORGANIZATIONID_KEY = 'x-organization-id';
const HEADER_ORGANIZATIONID_IDENTIFIER = 'organizationId';
const HEADER_ORGANIZATIONID_IDENTITY = 'Organization';

const HEADER_LANGUAGE_KEY = 'x-language';
const HEADER_LANGUAGE_IDENTIFIER = 'language';
const HEADER_LANGUAGE_IDENTITY = 'Language';

const DEV_SIMULATE_TENANT_KEY = 'dev-simulate-tenant';
const DEV_SIMULATE_TENANT_IDENTIFIER = 'simulateTenant';
const DEV_SIMULATE_TENANT_IDENTITY = 'SimulateTenant';

const headerContext = {
  [HEADER_TENANTID_IDENTITY]: {
    key: HEADER_TENANTID_KEY,
    id: HEADER_TENANTID_IDENTIFIER,
  },
  [HEADER_USERID_IDENTITY]: {
    key: HEADER_USERID_KEY,
    id: HEADER_USERID_IDENTIFIER,
  },
  [HEADER_USERTOKEN_IDENTITY]: {
    key: HEADER_USERTOKEN_KEY,
    id: HEADER_USERTOKEN_IDENTIFIER,
  },
  [HEADER_ORGANIZATIONID_IDENTITY]: {
    key: HEADER_ORGANIZATIONID_KEY,
    id: HEADER_ORGANIZATIONID_IDENTIFIER,
  },
  [HEADER_LANGUAGE_IDENTITY]: {
    key: HEADER_LANGUAGE_KEY,
    id: HEADER_LANGUAGE_IDENTIFIER,
  },
  [DEV_SIMULATE_TENANT_IDENTITY]: {
    key: DEV_SIMULATE_TENANT_KEY,
    id: DEV_SIMULATE_TENANT_IDENTIFIER,
  },
};

export {
  headerContext,
  HEADER_TENANTID_IDENTITY,
  HEADER_USERID_IDENTITY,
  HEADER_USERTOKEN_IDENTITY,
  HEADER_ORGANIZATIONID_IDENTITY,
  HEADER_LANGUAGE_IDENTITY,
  DEV_SIMULATE_TENANT_IDENTITY,
};
