import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import { VisuallyHidden } from '@reach/visually-hidden';
import CloseIcon from '@mui/icons-material/Close';

const styles = theme => ({
  modalContent: {
    ...theme.typography.body1,
  },
});

class DialogComponent extends React.Component {
  state = {};

  render() {
    const {
      classes,
      fullScreen,
      open,
      onClose,
      onExited,
      dialogTitle,
      actions,
      children,
      maxWidth,
      noMargin,
      disableEscapeKeyDown,
      applyStylesToRoot = false,
      ariaLabel = '',
      fullWidth,
      closeIconVisible,
      ignoreActions,
    } = this.props;

    const { modalContent, ...otherClasses } = classes;
    return (
      <div>
        <Dialog
          {...{
            fullScreen,
            open,
            onClose,
            maxWidth,
            disableEscapeKeyDown,
            fullWidth,
          }}
          TransitionProps={{
            onExited,
          }}
          aria-labelledby="dialog-title"
          classes={applyStylesToRoot ? otherClasses : {}}
        >
          {dialogTitle ? (
            <DialogTitle
              id="dialog-title"
              data-test="modal-dialog-title"
              className={classes.title}
              sx={{ padding: dialogTitle ? '12px 48px 12px 20px' : 'inherit' }}
            >
              <Typography variant="h3" component="span">
                {dialogTitle}
              </Typography>
              {closeIconVisible && (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </DialogTitle>
          ) : ariaLabel ? (
            <VisuallyHidden id="dialog-title">{ariaLabel}</VisuallyHidden>
          ) : null}
          <Divider />
          <DialogContent
            classes={otherClasses}
            className={modalContent}
            style={noMargin ? { padding: 0 } : {}}
            data-test="modal-dialog-content"
          >
            {children}
          </DialogContent>
          {actions && !ignoreActions && (
            <DialogActions data-test="modal-dialog-actions">{actions}</DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

DialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  dialogTitle: PropTypes.node,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  noMargin: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
};

DialogComponent.defaultProps = {
  fullScreen: false,
  open: false,
  onClose: undefined,
  onExited: undefined,
  dialogTitle: '',
  actions: undefined,
  maxWidth: 'md',
  noMargin: false,
  disableEscapeKeyDown: false,
};

export default withStyles(styles)(DialogComponent);
