import React, { lazy, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { initializeT } from 'UI/lib';
import SplashScreen from './ui/containers/SplashScreen';
import RestrictedRoute from 'UI/wrappers/restricted-route';
import RestrictedToUnauthenticatedRoute from './ui/wrappers/restricted-to-unauthenticated-route';
import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import { ERROR_TYPES } from 'UI/constants/errorTypes';

const Restrict = lazy(() => import('UI/pages/restrict/restrict'));
const Onboarding = lazy(() => import('UI/pages/onboarding/onboarding'));
const LoggedOut = lazy(() => import('UI/pages/LoggedOut/LoggedOut'));
const RootWrapper = lazy(() => import('UI/wrappers/root/root'));

const RouterSwitch = ({ t, tReady, location }) => {
  // `tReady` comes from react-i18next library.
  // Once this library is updated, `translate` HoC can be replaced with hooks alternative.
  const [isTInitialized, setIsTInitialized] = useState(false);
  useEffect(() => {
    if (tReady) {
      initializeT(t);
      setIsTInitialized(true);
    }
  }, [tReady, t]);

  if (!isTInitialized) {
    return <SplashScreen />;
  }
  const { search, hash } = location;
  return (
    <LazyComponent errorDisplayType={ERROR_TYPES.PAGE} fallback={<SplashScreen />}>
      <RootWrapper>
        <Switch>
          <RestrictedRoute
            fallbackPath="/onboarding"
            path="/app"
            render={props => (
              <LazyComponent errorDisplayType={ERROR_TYPES.PAGE} fallback={<SplashScreen />}>
                <Restrict {...props} />
              </LazyComponent>
            )}
          />
          <RestrictedRoute
            checkAccessPredicate={userDetails => !userDetails.hasAccessToRestrictedPages}
            fallbackPath="/app/overview"
            path="/onboarding"
            render={props => (
              <LazyComponent errorDisplayType={ERROR_TYPES.PAGE} fallback={<SplashScreen />}>
                <Onboarding {...props} />
              </LazyComponent>
            )}
          />
          <RestrictedToUnauthenticatedRoute
            fallbackPath="/app"
            path="/logged-out"
            render={props => (
              <LazyComponent errorDisplayType={ERROR_TYPES.PAGE}>
                <LoggedOut {...props} />
              </LazyComponent>
            )}
          />
          <Redirect
            to={{
              pathname: '/app/overview',
              search,
              hash,
            }}
          />
        </Switch>
      </RootWrapper>
    </LazyComponent>
  );
};

export default withTranslation(['common', 'restrict', 'auth', 'notifications_in_app'], {
  usePureComponent: true,
})(withRouter(RouterSwitch));
