import StatusIcons from 'UI/constants/StatusIcons';
function urlToFile(url, filename, mimeType) {
  const type = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  const byteCharacters = atob(url.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type });

  blob.name = filename;

  return blob;
}

const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

const parseJsonSafe = (json, fallbackValue) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return fallbackValue;
  }
};

const findDomElementAncestor = (element, predicate) => {
  if (predicate(element.parentElement)) {
    return element.parentElement;
  }

  if (element === document.body) {
    return null;
  }

  return findDomElementAncestor(element.parentElement, predicate);
};

// Function to combine two or more Account.styles.js files
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map(arg => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

// This helper already exists in different form as a hook in
// ui/customHooks/useQuery.js. Since hooks cannot be used in React classes
// I created the following
const getUrlQueryParam = parameter => {
  const search = window.location.search;
  return new URLSearchParams(search).get(parameter);
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const showStatusIcon = (value, primaryColor) => {
  const statuses = StatusIcons(primaryColor);
  if (value) {
    return statuses[value];
  }
  return statuses.pending;
};

const getRandomInt = (min, max) => {
  return min + Math.round(Math.random() * (max - min));
};

const getIsNumber = value => {
  return typeof value === 'number';
};

export {
  urlToFile,
  isValidUrl,
  parseJsonSafe,
  findDomElementAncestor,
  combineStyles,
  getUrlQueryParam,
  capitalizeFirstLetter,
  showStatusIcon,
  getRandomInt,
  getIsNumber,
};
