import React, { lazy } from 'react';

const CancelCircleSvg = lazy(() => import('UI/utils/icons/CancelCircleSvg'));
const PendingIconSvg = lazy(() => import('UI/utils/icons/PendingIconSvg'));
const CheckCircleSvg = lazy(() => import('UI/utils/icons/checkCircleSvg'));

const StatusIcons = primaryColor => {
  return {
    rejected: <CancelCircleSvg />,
    confirmed: <CheckCircleSvg />,
    pending: <PendingIconSvg fill={primaryColor} />,
  };
};

export default StatusIcons;
