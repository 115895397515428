const getOrganizationIdKeyEncr = userId => {
  if (!userId) {
    return null;
  }
  return `currentOrganization_${userId}`;
};

export const getSelectedOrganizationIdEncr = userId => {
  const organizationIdKey = getOrganizationIdKeyEncr(userId);
  if (!organizationIdKey) {
    return null;
  }
  return localStorage.getItem(organizationIdKey);
};

export const setSelectedOrganizationIdEncr = (organizationId, userId) => {
  if (!organizationId || organizationId === 'null') {
    return false;
  }
  const organizationIdKey = getOrganizationIdKeyEncr(userId);
  if (!organizationIdKey) {
    return false;
  }
  localStorage.setItem(organizationIdKey, organizationId);
  return true;
};
