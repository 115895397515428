import Spinner from 'UI/pages/restrict/company/TeamReadiness/TeamReadinessDetails/ui/MemberDetails/Spinner';
import React, { Suspense } from 'react';
import ErrorBoundary from 'UI/pages/ErrorBoundary/ErrorBoundary';
import { ERROR_TYPES } from 'UI/constants/errorTypes';

const LazyComponent = ({
  fallback = <Spinner />,
  errorDisplayType = ERROR_TYPES.COMPONENT,
  dialogHelper = () => {},
  children,
  ...other
}) => {
  return (
    <Suspense fallback={fallback}>
      <ErrorBoundary dialogHelper={dialogHelper} errorDisplayType={errorDisplayType}>
        {React.cloneElement(children, { ...other })}
      </ErrorBoundary>
    </Suspense>
  );
};

export default LazyComponent;
