import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';

export default withStyles(theme => ({
  Container: {
    padding: theme.spacing(5) * 2,
    justifyContent: 'center',
  },
}))(({ classes }) => (
  <Grid container className={classes.Container}>
    <Grid item>
      <CircularProgress aria-label="circular progress icon" />
    </Grid>
  </Grid>
));
