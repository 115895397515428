import React, { Component, lazy } from 'react';
import { Grid } from '@mui/material';
import LXPHeaderPlaceholder from 'UI/components/LXPHeaderImage/LXPHeaderPlaceholder';
import i18next from 'i18next';
import { ERROR_TYPES } from 'UI/constants/errorTypes';
import DialogCustom from 'UI/components/dialogCustom/dialogCustom';
import ErrorIcon from '@mui/icons-material/Error';

const FullWidthContainer = lazy(() =>
  import('UI/components/FullWidthContainer/FullWidthContainer')
);
const TooltipCustom = lazy(() => import('UI/components/tooltipCustom/tooltipCustom'));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, openErrorDialog: false };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.hasError !== this.state.hasError &&
      this.state.hasError &&
      this.props.errorDisplayType === ERROR_TYPES.DIALOG
    ) {
      this.setState({ openErrorDialog: true });
    }
  }

  retry = () => {
    this.setState({ hasError: false, openErrorDialog: false });
  };

  handleClose = () => {
    this.setState({ hasError: false, openErrorDialog: false });
    this.props.dialogHelper();
  };

  render() {
    const { errorDisplayType = ERROR_TYPES.COMPONENT } = this.props;
    if (this.state.hasError) {
      return (
        <>
          {errorDisplayType === ERROR_TYPES.PAGE && (
            <FullWidthContainer>
              <LXPHeaderPlaceholder />
              <Grid container alignItems="center" columnGap={4}>
                {i18next.t('restrict:error.boundary.page')}
              </Grid>
            </FullWidthContainer>
          )}
          {errorDisplayType === ERROR_TYPES.COMPONENT && (
            <FullWidthContainer>
              <Grid container alignItems="center" columnGap={4}>
                {i18next.t('restrict:error.boundary.component')}
              </Grid>
            </FullWidthContainer>
          )}
          {errorDisplayType === ERROR_TYPES.ELEMENT && (
            <Grid container alignItems="center" columnGap={4}>
              <TooltipCustom
                dataTest="error-icon-tooltip"
                title={i18next.t('restrict:error.boundary.element')}
              >
                <ErrorIcon color="error" />
              </TooltipCustom>
            </Grid>
          )}
          {errorDisplayType === ERROR_TYPES.DIALOG && (
            <DialogCustom
              onClose={() => this.handleClose()}
              open={this.state.openErrorDialog}
              closeIconVisible
              dialogTitle={i18next.t('restrict:error.boundary.dialog_title')}
            >
              {i18next.t('restrict:error.boundary.dialog_content')}
            </DialogCustom>
          )}
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
