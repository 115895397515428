import pickBy from 'lodash/pickBy';
import {
  headerContext,
  HEADER_TENANTID_IDENTITY,
  HEADER_USERTOKEN_IDENTITY,
  HEADER_USERID_IDENTITY,
  HEADER_LANGUAGE_IDENTITY,
  DEV_SIMULATE_TENANT_IDENTITY,
  HEADER_ORGANIZATIONID_IDENTITY,
} from 'UI/constants/apiHeaders';

const SIMULATE_TENANT = process.env.SIMULATE_TENANT;

/**
 * Builds BE API headers with correct header keys and omitting the invalid values
 * @param {string} [userId]
 * @param {string} [token]
 * @param {string} [language]
 * @param {string} [organizationId]
 */
export const getApiHeaders = ({ userId, token, language = 'en', organizationId }) => {
  return pickBy(
    {
      [headerContext[HEADER_USERID_IDENTITY].key]: userId,
      [headerContext[HEADER_USERTOKEN_IDENTITY].key]: token,
      [headerContext[HEADER_LANGUAGE_IDENTITY].key]: language,
      [headerContext[HEADER_ORGANIZATIONID_IDENTITY].key]: organizationId,
      [headerContext[HEADER_TENANTID_IDENTITY].key]: window.rootOrganizationId,
      [headerContext[DEV_SIMULATE_TENANT_IDENTITY].key]: SIMULATE_TENANT
        ? 'https://helsana.next.palyx.org/'
        : undefined,
    },
    value => !!value && value !== 'null'
  );
};
