import { navigateExternal, navigateInternal, clientLogger as logger } from 'UI/lib';
import {
  getOpportunityFilterStorageKey,
  OPPORTUNITY_FILTER_SUFFIXES,
} from 'UI/pages/restrict/opportunities/OpportunitiesFilterPanel/OpportunitiesFilterContext/useOrganizationFilterSuffix';
import { getIsInternalUrl } from 'UI/lib/getUrlType';

import getApolloClient from '../getApolloClient';
import getBackend from '../getBackend';
import getIsRestrictArea from './getIsRestrictArea';
import {
  AUTH_DEFAULT_LOGIN_REDIRECT_URL,
  AUTH_ERROR_CODE_QUERY_PARAM,
  AUTH_ERROR_REASON_QUERY_PARAM,
} from './constants';
import { getUrlQueryParam } from 'UI/lib/helpers';
import { loginSsoUser } from './loginSsoUser';
import {
  LOCAL_STORAGE_LAST_ACTION_TIMESTAMP,
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_USER_TOKEN,
} from 'UI/constants/localStorage';
import { getApiHeaders } from '../getApiHeaders';

const getBackEndAuthUrl = path => `${getBackend()}/auth/${path}`;

const handleNavigation = redirectUrlOrPath => {
  const isInternalRedirect = getIsInternalUrl(redirectUrlOrPath);
  const navigate = isInternalRedirect ? navigateInternal : navigateExternal;
  navigate(redirectUrlOrPath);
};

export const getBrowserUserCredentials = () => {
  const palyxUserId = localStorage.getItem(LOCAL_STORAGE_USER_ID);
  const palyxToken = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);

  return {
    palyxUserId,
    palyxToken,
  };
};

export const setBrowserUserCredentials = userCredentials => {
  const { palyxUserId, palyxToken } = userCredentials;

  if (palyxUserId) {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, palyxUserId);
  }

  if (palyxToken) {
    localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, palyxToken);
  }
};

const removeUserStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_LAST_ACTION_TIMESTAMP);
  localStorage.removeItem(LOCAL_STORAGE_USER_ID);
  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);

  for (const suffix in Object.values(OPPORTUNITY_FILTER_SUFFIXES)) {
    localStorage.removeItem(getOpportunityFilterStorageKey(suffix));
  }

  localStorage.removeItem('personalDevelopmentFilters');
};

/**
 * Logs out the user and redirect. If redirectOverride is provided, it will be used instead of the default redirect.
 * @param {string} redirectOverride - The redirect url or path. If false, redirect will be skipped.
 * @return {Promise.<void>}
 */
export const logoutUser = async redirectOverride => {
  const apolloClient = getApolloClient();
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();
  const actions = [];

  if (palyxToken) {
    actions.push(
      fetch(getBackEndAuthUrl('user/logout'), {
        headers: getApiHeaders({ userId: palyxUserId, token: palyxToken }),
        method: 'POST',
      })
    );
  }

  if (apolloClient) {
    actions.push(apolloClient.clearStore());
  }

  const [logoutRes] = await Promise.all(actions);

  removeUserStorage();

  if (redirectOverride) {
    handleNavigation(redirectOverride);
    return true;
  }

  if (redirectOverride === false) {
    return;
  }

  if (logoutRes?.status === 200) {
    const body = await logoutRes.json();
    const isValidRedirectUrl = body?.redirectUrl?.startsWith('http');
    if (isValidRedirectUrl) {
      navigateExternal(body.redirectUrl);
      return true;
    }
  }

  navigateInternal('/logged-out');
  return true;
};

export const ssoInit = async () => {
  const redirectUri = getUrlQueryParam('redirect_uri');
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();

  const headers = getApiHeaders({ userId: palyxUserId, token: palyxToken });

  const response = await fetch(
    getBackEndAuthUrl(
      `sso/init${redirectUri ? `?redirectUri=${encodeURIComponent(redirectUri)}` : ''}`
    ),
    { headers }
  );

  if (response.status === 200) {
    try {
      const { location } = await response.json();
      const isValidUri = location.includes('://');
      if (isValidUri) {
        navigateExternal(location);
        return;
      }
    } catch (error) {
      logger.error(error, `Error while parsing SSO init response: ${error.message}`);
    }
  }

  logger.debug('Tenant is not configured for SSO. Redirecting to login page.');
};

/**
 * @return {Promise.<void>}
 */
export const checkUserSession = async () => {
  const { pathname, search, hash, href } = window.location;
  const currentUrl = new URL(href);
  const isRestrict = getIsRestrictArea(currentUrl);
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();
  const headers = getApiHeaders({ userId: palyxUserId, token: palyxToken });
  const isUserLogged = Boolean(palyxToken && palyxUserId);

  if (isUserLogged) {
    const response = await fetch(getBackEndAuthUrl('user/info?isCheckIdpSession=true'), {
      headers: getApiHeaders({ userId: palyxUserId, token: palyxToken }),
    });

    if (response.ok) {
      if (!isRestrict) {
        navigateInternal(AUTH_DEFAULT_LOGIN_REDIRECT_URL);
        return true;
      }
    } else {
      // Unauthorized
      if (response.status === 401) {
        return await logoutUser(window.location.pathname);
      }

      return await logoutUser(`/?${AUTH_ERROR_CODE_QUERY_PARAM}=${response.status}`);
    }
  }

  const ssoCode = currentUrl.searchParams.get('code');
  const ssoState =
    currentUrl.searchParams.get('state') || encodeURIComponent(AUTH_DEFAULT_LOGIN_REDIRECT_URL);

  const isSsoCallback = Boolean(ssoCode?.trim());

  if (isSsoCallback) {
    return loginSsoUser({
      code: ssoCode,
      state: ssoState,
    });
  }

  const redirectUri =
    getUrlQueryParam('redirect_uri') ?? encodeURIComponent(`${pathname}${search}${hash}`);

  const ssoInit = await fetch(
    getBackEndAuthUrl(`sso/init${redirectUri ? `?redirectUri=${redirectUri}` : ''}`),
    { headers }
  );

  // No SSO redirect
  if (ssoInit.status === 204) {
    return;
  }

  if (ssoInit.status === 200) {
    try {
      const { location } = await ssoInit.json();
      const isValidLocation = location.includes('://');
      if (isValidLocation) {
        navigateExternal(location);
        return true;
      }
    } catch (error) {
      logger.error(error, `Error while parsing SSO init response: ${error.message}`);
    }
  }

  navigateInternal(
    `/?${AUTH_ERROR_CODE_QUERY_PARAM}=500&${AUTH_ERROR_REASON_QUERY_PARAM}=invalid SSO init response`
  );
  return true;
};
